<template>
  <action-dispatcher ref="dispatcher" action="answers/get">
    <div class="view-answers">
      <div class="row justify-content-end align-items-center mb-5">
        <div class="col-lg-6">
          <form-group
            v-model="search"
            id="search"
            label="Pesquise por nome ou e-mail do aluno"
            placeholder="Ex: John Doe ou johndoe@gmail.com"
          />
          <small class="text-secondary">
            Pesquise por nome ou e-mail do aluno
          </small>
        </div>
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-lg-8 mb-4 mb-lg-0">
              <button class="btn btn-primary btn-block" @click="submitSearch">
                <b-spinner
                  v-if="search_loading"
                  variant="light"
                  small
                ></b-spinner>
                <span v-else>Pesquisar</span>
              </button>
            </div>
            <div class="col-lg-4">
              <button
                v-if="search.length"
                class="btn text-danger font-weight-normal"
                @click="clearSearch"
              >
                Limpar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-none d-lg-flex">
        <div class="col-lg-4">
          <h6>Nome</h6>
        </div>
        <div class="col-lg-4">
          <h6>E-mail</h6>
        </div>
        <div class="col-lg-4 text-right">
          <h6>Nota</h6>
        </div>
      </div>
      <div v-if="answers.length" class="d-flex flex-column">
        <router-link
          class="btn card mb-4"
          v-for="(answer, index) in list"
          :key="index"
          :to="{ name: 'admin-user-answer', params: { id: answer.id } }"
        >
          <div class="row align-items-center w-100">
            <div class="col-lg-4">
              <h6 class="text-left font-weight-normal m-0 p-0">
                <span class="d-lg-none">Nome: </span><br class="d-lg-none" />
                {{ answer.name }}
              </h6>
            </div>
            <div class="col-lg-4">
              <p class="text-left m-0 p-0">
                <span class="d-lg-none">E-mail: </span><br class="d-lg-none" />
                {{ answer.email }}
              </p>
            </div>
            <div class="col-lg-4 text-left text-lg-right">
              <p>
                <span class="d-lg-none">Score: </span><br class="d-lg-none" />
                {{ getScore(answer) }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
      <div v-else class="text-center text-secondary my-4">
        <p>Sem respostas enviadas até o momento</p>
      </div>

      <div class="d-flex justify-content-center">
        <button
          v-if="show_more"
          class="btn btn-outline-primary"
          @click="loadMore"
        >
          <b-spinner v-if="loading_more" variant="light" small></b-spinner>
          <span v-else>Ver mais</span>
        </button>
        <p v-else class="text-secondary">
          Este é o final da lista.
        </p>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import { clearObject } from "@/functions/helpers";
import { mapState } from "vuex";
export default {
  mounted() {
    this.$store.dispatch("questions/get");
  },
  data() {
    return {
      show_more: true,
      loading_more: false,
      search_loading: false,
      search_results: [],
      search: this.$route.query.search || ""
    };
  },
  computed: {
    ...mapState("questions", ["questions"]),
    ...mapState("answers", ["answers"]),
    list() {
      if (this.search_results.length) return this.search_results;

      return this.answers;
    }
  },
  methods: {
    getScore(customer_answer) {
      const length = customer_answer.answers.length;

      const correct_length = customer_answer.answers.filter(a => {
        const right_alternative = a.alternatives.find(at => at.is_right);
        return right_alternative && a.answer == right_alternative.id;
      }).length;

      const percent = Math.ceil((correct_length * 100) / length);
      return `${percent}%`;
    },
    clearSearch() {
      this.search = "";
      this.search_results = [];
    },
    submitSearch() {
      if (this.search_loading) return;

      this.search_loading = true;
      this.$store
        .dispatch("answers/search", this.search)
        .then(data => {
          this.search_results = data;
        })
        .finally(() => {
          this.search_loading = false;
        });
    },
    loadMore() {
      if (this.loading_more) return;

      this.loading_more = true;
      this.$store
        .dispatch("answers/get")
        .then(data => {
          if (data.length == 0) {
            this.show_more = false;
          }
        })
        .finally(() => {
          this.loading_more = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-answers {
  .card {
    flex-direction: row;
    align-items: center;
    justify-content: center;

    h6,
    p {
      font-size: 14px;
      font-weight: normal;

      span {
        font-weight: bold;
      }
    }
  }
}
</style>
